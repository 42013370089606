<!--用电电压分布-->
<template>
    <widget-framework v-if="dataLoaded" title="用电电压分布" @click="$router.push('/business/customerAccount')">
        <template #content>
            <div class="container">
                <div class="pieChartContainer">
                    <v-chart :options="pieOpt" :autoresize="true" class="pieChart"></v-chart>
                </div>
                <div class="barChartContainer">
                    <v-chart :options="barOpt" :autoresize="true" class="barChart"></v-chart>
                </div>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import EchartBase from '@/components/widgets/EchartBase';
import WidgetBase from '@/components/widgets/WidgetBase';
import {
    blue,
    cyan,
    filledCircle,
    green, grey,
    lightGrey, red,
    tooltip,
    white, whiteOpacity,
    yellow,
} from '@/components/widgets/constant';

export default {
    name: 'ElectricityVoltageDistribution',
    components: {
        WidgetFramework
    },
    mixins: [WidgetBase, EchartBase],
    data() {
        return {
            dataLoaded: false,
            pieOpt: {
                textStyle: {
                    color: lightGrey
                },
                tooltip: {
                    trigger: 'item',
                    position: this.setTooltipPosition,
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText
                },
                dataset: [],
                series: [
                    {
                        name: '用户总数',
                        type: 'pie',
                        radius: [0, '35%'],
                        hoverAnimation: false,
                        itemStyle: {
                            color: filledCircle
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `{a|${value}}\n{b|${name}}`;
                            },
                            rich: {
                                a: {
                                    color: yellow,
                                    fontSize: 20,
                                    height: 30,
                                    fontWeight: 'bold'
                                },
                                b: {
                                    color: white,
                                    fontSize: 10
                                }
                            }
                        },
                        labelLine: {
                            show: false,
                        },
                        datasetIndex: 0,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}`;
                            }
                        }
                    },
                    {
                        name: '用户情况',
                        type: 'pie',
                        radius: ['35%', '45%'],
                        startAngle: 45,
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `{b|${name}}：{a|${value || 0}}`;
                            },
                            alignTo: 'labelLine',
                            rich: {
                                a: {
                                    color: green,
                                    fontSize: '16px'
                                },
                                b: {
                                    color: white,
                                    fontSize: '16px'
                                }
                            }
                        },
                        labelLine: {
                            show: true,
                            length: 40,
                            length2: 0,
                        },
                        emphasis: {
                            label: {
                                show: false,
                            },
                        },
                        datasetIndex: 1,
                        encode: {
                            value: 'value'
                        },
                        tooltip: {
                            formatter: (params) => {
                                const {name, value} = params.data;
                                return `${name}：${value}`;
                            }
                        }
                    },
                    {
                        type: 'pie',
                        radius: ['60%', '65%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    },
                    {
                        type: 'pie',
                        radius: ['70%', '75%'],
                        legendHoverLink: false,
                        hoverAnimation: false,
                        itemStyle: {
                            color: whiteOpacity
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [1],
                        markPoint: {
                            symbol: 'none'
                        },
                        tooltip: {
                            show: false,
                        },
                        z: 0
                    }
                ]
            },
            barOpt: {
                textStyle: {
                    color: lightGrey
                },
                grid: {
                    bottom: 0,
                    top: 20,
                    left: 30,
                    right: 0,
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    position: this.setTooltipPosition,
                    axisPointer: {
                        type: 'shadow',
                        shadowStyle: {
                            color: cyan,
                            opacity: 0.2
                        }
                    },
                    backgroundColor: tooltip.backgroundColor,
                    borderColor: tooltip.borderColor,
                    borderWidth: tooltip.borderWidth,
                    extraCssText: tooltip.extraCssText,
                    formatter(params) {
                        const {permanentUser, temporaryUser} = params[0].data;
                        return `永久用户：${permanentUser || 0}<br/>临时用户：${temporaryUser || 0}<br/>`;
                    },
                },
                dataset: {
                    source: []
                },
                xAxis: {type: 'value', show: false},
                yAxis: {
                    type: 'category',
                    axisLine: {show: false},
                    axisTick: {show: false},
                    axisLabel: {
                        show: true,
                        verticalAlign: 'bottom',
                        lineHeight: 30,
                        inside: true
                    },
                    inverse: true,
                },
                series: [
                    {
                        type: 'bar',
                        name: '用户数',
                        stack: '用户数',
                        barWidth: 10,
                        barGap: '50%',
                        barCategoryGap: '50%',
                        itemStyle: {
                            color: blue,
                            barBorderRadius: [5, 0, 0, 5],
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'transparent',
                            barBorderRadius: 5,
                            borderColor: grey,
                            borderWidth: 1,
                            shadowColor: grey,
                            shadowBlur: 2,
                        },
                        encode: {
                            x: 'permanentUser',
                            y: 'voltageName'
                        },
                    },
                    {
                        type: 'bar',
                        name: '用户数',
                        stack: '用户数',
                        barWidth: 10,
                        itemStyle: {
                            color: yellow,
                            barBorderRadius: [0, 5, 5, 0],
                        },
                        showBackground: true,
                        backgroundStyle: {
                            color: 'transparent',
                            barBorderRadius: 5,
                            borderColor: grey,
                            borderWidth: 1,
                            shadowColor: grey,
                            shadowBlur: 2,
                        },
                        barGap: '50%',
                        barCategoryGap: '50%',
                        encode: {
                            x: 'temporaryUser',
                            y: 'voltageName',
                        },
                    },
                ],
            },
        };
    },
    mounted() {
        this.$client.listElectricityVoltageDistribution().then((ret) => {
            const {data = []} = ret;
            const dataset1 = [];
            let totalAmount = 0;
            let totalPermanentUser = 0;
            let totalTemporaryUser = 0;
            data.forEach((item) => {
                totalAmount = totalAmount + item.permanentUser + item.temporaryUser;
                totalPermanentUser += item.permanentUser;
                totalTemporaryUser += item.temporaryUser;
            });
            dataset1.push({name: '永久用户', value: totalPermanentUser, itemStyle: {color: blue}});
            dataset1.push({name: '临时用户', value: totalTemporaryUser, itemStyle: {color: yellow}});

            const dataset = [];
            dataset.push({source: [{name: '用户数量', value: totalAmount}]});
            dataset.push({source: dataset1});
            this.pieOpt.dataset = dataset;
            this.barOpt.dataset.source = data.map((item) => {
                const {voltageName, permanentUser, temporaryUser} = item;
                const title = `${voltageName}： 共 ${permanentUser + temporaryUser} 户`;
                return {title, permanentUser, temporaryUser};
            });
            this.dataLoaded = true;
        });
    },
};
</script>

<style lang="less" scoped>
    .container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;

        .pieChartContainer {
            width: 40%;

            .pieChart {
                width: 100%;
                height: 100%;
            }
        }

        .barChartContainer {
            flex-grow: 1;

            .barChart {
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
